.cont-quimioc {
    background-color: #028d7b; 
    height: 100%;
    width: 100%;
    align-Content: center;
    justify-Content: center;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;

}
.cont-spinner{
    align-items: center;
    display: flex;
    justify-content: center;
    position:fixed;
    left: 40%;
    right: 0;
    margin: 0 auto; 
    width: 25%;
    height: 385px;
    z-index: 150;
    

}
.spinner{ 
    text-align: center;
    display: block;
    margin: 0 auto;
    border-color: red;
}

.cont-tituloquimioc {
    text-align: center;
}
.tituloquimioc {
    text-align: center;
    font-size: 25px;
    color: #fff;   
}
.titulo-cerrarquimioc {
    font-size: 30px;
    color: red;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font: bold;
    cursor: pointer;
    float: right;
}

.cont-opcion {
    color: #fff;
    font-size: 14px;
    text-align: left;
    margin-bottom: 15px;
    
}

#cedula2, #edad2, #nombre2, #fechad2, #diase2, #diash2, #laboratorio2, #ecog2, #endoscopia2, #ninguno2, #anexos2, #radiologia2, #tac2, #otros2, #biopsia2, #quirurgico2, #medico2 {
    width: 100%;
    outline: none;
    margin: 0;
    font-size: 18px;
    height: 35px;
} 
#sintoma2, #diagnostico2, #examenf2, #procedimiento2 {
    width: 100%s; 
    outline: none;
    margin: 0;
    font-size: 14px;
    /* height: 25px; */
} 
    
.botongr{
    height: 40px !important;
    margin-top: 10px;
}
.opcion{
    font-size: 20px;
    font: bold;
}
.cont-evograi{
    align-items: center;
    display: flex;
    justify-content: center;
}
.evograi{
    text-align: center;
    background-color: #028d7b;
    height: 100%;
    width: 100%;
}
.titulo-listevoi{
    font-size: 25px;
    color: #48e120;
    text-align: center;
}

.listevograi {
    border: 15px;
    /* position:fixed; */
    top: 35%;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 220px;
    justify-content: center;
    font-size: 16px;
    width: auto;
    overflow: auto;
  
    background-color:  #028d7b;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;
    }

    .evotabla2grai {
      color: #fff;
      background-color:  #028d7b;
      width: 100%;
      border: 1px solid  #028d7b;
      text-align: left;
      border-collapse: collapse;
      margin: 0 0 1em 0;
      caption-side: top;
    }
    .card{
        padding: 10px;
        margin-left: 10px;
        margin-top: 10px;
    }
    .odd{
        background-color: #11a794;
    }
    .evocuerpo1 {
        cursor: pointer;
    }

    #buscar{
        margin-top: 30px;
        background-color: #48e120;
    }
    .form-check{
        margin-left: 10px;
        color: #fff;
        margin-bottom: 15px;
        font-size: 20px;
    }
    .icono-izquierdac {
        margin-right: 18px;
        font-size: 35px;
        color: red;
        position: absolute;
        float: right;
        right: 0;
        top: 3px;
        cursor: pointer;
    
      }
      .icono-derecho {
        margin-right: 10px;
        font-size: 10px;
        color: red;
        position: absolute;
        float: right;
        left: 3px;
        top: 5px;
        cursor: pointer;
    
      }

      .react-calendar { 
        width: 400px;
        max-width: 100%;
        background-color: #fff;
        color: #222;
        border-radius: 8px;
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1.125em;
        margin-bottom: 15px;
       }


       .react-calendar__navigation button {
        color: #6f48eb;
        min-width: 44px;
        background: none;
        font-size: 16px;
        margin-top: 8px;
       }
       .react-calendar__navigation button:enabled:hover,
       .react-calendar__navigation button:enabled:focus {
        background-color: #f8f8fa;
       }
       .react-calendar__navigation button[disabled] {
        background-color: #f0f0f0;
       }
       abbr[title] {
        text-decoration: none;
       }
       /* .react-calendar__month-view__days__day--weekend {
        color: #d10000;
       } */
       .react-calendar__tile:enabled:hover,
       .react-calendar__tile:enabled:focus {
        background: #f8f8fa;
        color: #6f48eb;
        border-radius: 6px;
       }
       .react-calendar__tile--now {
        background: #6f48eb33;
        border-radius: 6px;
        font-weight: bold;
        color: #6f48eb;
       }
       .react-calendar__tile--now:enabled:hover,
       .react-calendar__tile--now:enabled:focus {
        background: #6f48eb33;
        border-radius: 6px;
        font-weight: bold;
        color: #6f48eb;
       }
       .react-calendar__tile--hasActive:enabled:hover,
       .react-calendar__tile--hasActive:enabled:focus {
        background: #f8f8fa;
       }
       .react-calendar__tile--active {
        background: #6f48eb;
        border-radius: 6px;
        font-weight: bold;
        color: white;
       }
       .react-calendar__tile--active:enabled:hover,
       .react-calendar__tile--active:enabled:focus {
        background: #6f48eb;
        color: white;
       }
       .react-calendar--selectRange .react-calendar__tile--hover {
        background-color: #f8f8fa;
       }
       .react-calendar__tile--range {
        background: #f8f8fa;
        color: #6f48eb;
        border-radius: 0;
       }
       .react-calendar__tile--rangeStart {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        background: #6f48eb;
        color: white;
       }
       .react-calendar__tile--rangeEnd {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background: #6f48eb;
        color: white;
       }

       .cerrar {
        color: red;
        font-size: 15px;
        margin-left: 60px;
        font-weight: bold;
        cursor: pointer;
      
      }